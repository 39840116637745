import React from 'react';
import PropTypes from 'prop-types';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import styled from 'styled-components';

const Content = styled.div`
    background-image: url('/images/partners/bg-slider.png');
    background-repeat:none;
    border-radius:10px;
    margin-left: 25px;
    height: 550px;
    flex-grow: 1;
    flex-shrink:0;
`;

const Logo = styled.img`
    max-height : 38px;
`;

const Customer = styled.div`
    padding: 11%;
`;

const Title = styled.h3`
    font-size:24px;
    font-weight:300;
    color:#434343;
    margin: 3% 0%;
`;


const Text = styled.p`
    font-size:12px;
    font-weight:200;
    color:#434343;
`;

const TagContent = styled.div`
    display:flex;
    flex-direction:row;
`;

const Tag = styled.div`
    background-color:#227EE6;
    padding: 3% 8%;
    margin:1%;
    border-radius:50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: 0px 3px 11px 0px rgba(99, 99, 99, 0.55);
    width: fit-content;
`;

const TagText = styled.a`
    color:#FFF;
    font-size:12px;
    font-weight:300;
    margin:0px;

`;

const IconsCell = styled.div`
    display:flex;
    flex-direction:column;
    padding-top:11%;
`;

const CellIconItem = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
`;

const IconBlue = styled.img`
    width:20px;
    color:blue;
    margin-right:5%;
    flex-grow: 0;
    flex-shrink: 0;
`;

const Description = styled.p`
    font-size:12px;
    color:#434343;
    font-weight:200;
`;

const Tags = ({ textTag }) => {
    return (
        <Tag>
            <TagText>{textTag}</TagText>
        </Tag>
    );
};

Tags.propTypes = {textTag: PropTypes.string};

const IconContainer = ({
    iconImg, iconText
}) => {
    return (
        <CellIconItem>
            <IconBlue src={iconImg}
                alt='Representation of blue icon' />
            <Description>{iconText}</Description>
        </CellIconItem>
    );
};

IconContainer.propTypes = {
    iconImg: PropTypes.string,
    iconText: PropTypes.string
};

const Gallery = ({cards}) => {
    const handleOnDragStart = e => e.preventDefault();

    return (
        <AliceCarousel
            mouseDragEnabled
            buttonsDisabled
            stagePadding={{
                paddingLeft: 0,
                paddingRight: 0
            }}
            responsive={ {
                320: {items: 1},
                640: {items: 2},
                768: {items: 2},
                1024: {items: 3},
                1280: {items: 3}
            }}
        >
            {
                cards.map(({
                    titleCard, textCard, logoCustomer, containertag, icons
                }) => {
                    const tags = containertag.map(i=> (<Tags textTag={i.textTag} />));
                    const iconContainer = icons.map(i=> (<IconContainer iconImg={i.iconImg}
                        iconText={i.iconText}/>));
                    return (
                        <Content onDragStart={handleOnDragStart}>
                            <Customer>
                                <Logo src={logoCustomer}
                                    alt={`Representation of ${titleCard} logo`} />
                                <Title>{titleCard}</Title>
                                <Text>{textCard}</Text>
                                <TagContent>
                                    {tags}
                                </TagContent>
                                <IconsCell>
                                    {iconContainer}
                                </IconsCell>
                            </Customer>
                        </Content>
                    );
                })
            }
        </AliceCarousel>
    );
};

Gallery.propTypes = {cards: PropTypes.array};

export default React.memo(Gallery);
