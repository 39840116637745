import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

// Layout component
import Layout from '../components/layouts/Layout';
import TwoColumnsIcons from '../components/TwoColumnsIcons';
import Gallery from '../components/Gallery';
import BannerPicture from '../components/BannerPicture';
import SectionOnlyFooter from '../components/SectionOnlyFooter';

const TwoColumnsLayout = styled.div`
    margin: 4% 0%;
`;

const GalleryLayout = styled.div`
    margin: 6% 0%;
`;


const IndexPage = ({ data }) => {
    const { t } = useTranslation('partnersPage');

    const containertagYext = [
        {textTag: t('TAG_RETAIL')},
        {textTag: t('TAG_PDV')}
    ];

    const containertagAG = [
        {textTag: t('TAG_TRAVEL')}
    ];

    const containertagMichelin = [
        {textTag: t('TAG_TRAVEL')},
        {textTag: t('TAG_RESTO')}
    ];

    const containertagAFP = [
        {textTag: t('TAG_INFOS')}
    ];

    const containertagWcities = [
        {textTag: t('TAG_TRAVEL')}
    ];

    const containertagUbiflow = [
        {textTag: t('TAG_RETAIL')}
    ];

    const containertagApidae = [
        {textTag: t('TAG_TRAVEL')}
    ];

    const containertagPartoo = [
        {textTag: t('TAG_RETAIL')}
    ];

    const containertagOpenAgenda = [
        {textTag: t('TAG_TRAVEL')},
        {textTag: t('TAG_TOURIST')}
    ];

    const containertagArgo = [
        {textTag: t('TAG_AR')}
    ];


    const containertagPolestar = [
        {textTag: t('TAG_INDOOR')}
    ];

    const iconsYext = [
        {
            iconImg: '/images/partners/code_icon.svg',
            iconText: t('YEXT_TYPOLOGIE')
        },
        {
            iconImg: '/images/partners/desktop-active_icon.svg',
            iconText: t('YEXT_BRAND')
        },
        {
            iconImg: '/images/partners/arrow-alt-circle-up_icon.svg',
            iconText: t('YEXT_TYPE')
        }
    ];

    const iconsAG = [
        {
            iconImg: '/images/partners/code_icon.svg',
            iconText: t('AG_TYPOLOGIE')
        },
        {
            iconImg: '/images/partners/desktop-active_icon.svg',
            iconText: t('AG_BRAND')
        },
        {
            iconImg: '/images/partners/arrow-alt-circle-up_icon.svg',
            iconText: t('AG_TYPE')
        }
    ];

    const iconsM = [
        {
            iconImg: '/images/partners/code_icon.svg',
            iconText: t('M_TYPOLOGIE')
        },
        {
            iconImg: '/images/partners/desktop-active_icon.svg',
            iconText: t('M_BRAND')
        },
        {
            iconImg: '/images/partners/arrow-alt-circle-up_icon.svg',
            iconText: t('M_TYPE')
        }
    ];

    const iconsAFP = [
        {
            iconImg: '/images/partners/code_icon.svg',
            iconText: t('AFP_TYPOLOGIE')
        },
        {
            iconImg: '/images/partners/desktop-active_icon.svg',
            iconText: t('AFP_BRAND')
        },
        {
            iconImg: '/images/partners/arrow-alt-circle-up_icon.svg',
            iconText: t('AFP_TYPE')
        }
    ];

    const iconsW = [
        {
            iconImg: '/images/partners/code_icon.svg',
            iconText: t('WCITIES_TYPOLOGIE')
        },
        {
            iconImg: '/images/partners/desktop-active_icon.svg',
            iconText: t('WCITIES_BRAND')
        },
        {
            iconImg: '/images/partners/arrow-alt-circle-up_icon.svg',
            iconText: t('WCITIES_TYPE')
        }
    ];

    const iconsUbiflow = [
        {
            iconImg: '/images/partners/code_icon.svg',
            iconText: t('UBIFLOW_TYPOLOGIE')
        },
        {
            iconImg: '/images/partners/desktop-active_icon.svg',
            iconText: t('UBIFLOW_BRAND')
        },
        {
            iconImg: '/images/partners/arrow-alt-circle-up_icon.svg',
            iconText: t('UBIFLOWTYPE')
        }
    ];

    const iconsApidae = [
        {
            iconImg: '/images/partners/code_icon.svg',
            iconText: t('APIDAE_TYPOLOGIE')
        },
        {
            iconImg: '/images/partners/desktop-active_icon.svg',
            iconText: t('APIDAE_BRAND')
        },
        {
            iconImg: '/images/partners/arrow-alt-circle-up_icon.svg',
            iconText: t('APIDAE_TYPE')
        }
    ];

    const iconsPartoo = [
        {
            iconImg: '/images/partners/code_icon.svg',
            iconText: t('PARTOO_TYPOLOGIE')
        },
        {
            iconImg: '/images/partners/desktop-active_icon.svg',
            iconText: t('PARTOO_BRAND')
        },
        {
            iconImg: '/images/partners/arrow-alt-circle-up_icon.svg',
            iconText: t('PARTOO_TYPE')
        }
    ];


    const iconsArgo = [
        {
            iconImg: '/images/partners/code_icon.svg',
            iconText: t('ARGO_TYPOLOGIE')
        },
        {
            iconImg: '/images/partners/desktop-active_icon.svg',
            iconText: t('ARGO_BRAND')
        }
    ];

    const iconsOA = [
        {
            iconImg: '/images/partners/code_icon.svg',
            iconText: t('OA_TYPOLOGIE')
        },
        {
            iconImg: '/images/partners/desktop-active_icon.svg',
            iconText: t('OA_BRAND')
        }
    ];

    const iconsPolestar = [
        {
            iconImg: '/images/partners/code_icon.svg',
            iconText: t('POLESTAR_TYPOLOGIE')
        },
        {
            iconImg: '/images/partners/desktop-active_icon.svg',
            iconText: t('POLESTAR_BRAND')
        }
    ];


    return (
        <React.Fragment>
            <Helmet defer={false}>
                <title>{t('META_TITLE_PARTNER')}</title>
                <meta name="description"
                    content={t('META_DESCRIPTION_PARTNER')}/>
            </Helmet>
            <Layout>
                <div className='wemap-page-index'>
                    <h1 className='wemap-page-index-title'
                        dangerouslySetInnerHTML={{ __html: t('TITLE') }}></h1>

                    <SectionOnlyFooter
                        titleSection={t('PUBLIC_WEMAP_PLATFORM')}
                        cellParagraph={t('TECHNO_SOLUTIONS')}
                        pictureRight={data.partners.childImageSharp}
                        altDescription='mobiles with article on one screen and a Wemap map on the other screen'
                        titleButton={t('CTA_CONTACT')}
                        urlFooter='/contact/'
                    />

                    <TwoColumnsLayout>
                        <TwoColumnsIcons
                            title={t('CONTENT_PARTNERS')}
                            text={t('MILLIONS_VISITORS')}
                            titleButton={t('CTA_CONTENT')}
                            urlFooter='/contact/'
                        />
                    </TwoColumnsLayout>
                    <hr className='line' />


                    <GalleryLayout>
                        <Gallery
                            cards={[
                                {
                                    logoCustomer: '/images/partners/yext.svg',
                                    titleCard: t('YEXT_TITLE'),
                                    textCard: t('YEXT_DESCRIPTION'),
                                    containertag: containertagYext,
                                    icons: iconsYext
                                },
                                {
                                    logoCustomer: '/images/partners/logo_arrival_guides_1.svg',
                                    titleCard: t('AG_TITLE'),
                                    textCard: t('AG_DESCRIPTION'),
                                    containertag: containertagAG,
                                    icons: iconsAG
                                },
                                {
                                    logoCustomer: '/images/partners/michelin.svg',
                                    titleCard: t('M_TITLE'),
                                    textCard: t('M_DESCRIPTION'),
                                    containertag: containertagMichelin,
                                    icons: iconsM
                                },
                                {
                                    logoCustomer: '/images/partners/afp.svg',
                                    titleCard: t('AFP_TITLE'),
                                    textCard: t('AFP_DESCRIPTION'),
                                    containertag: containertagAFP,
                                    icons: iconsAFP
                                },
                                {
                                    logoCustomer: '/images/partners/wcities.svg',
                                    titleCard: t('WCITIES_TITLE'),
                                    textCard: t('WCITIES_DESCRIPTION'),
                                    containertag: containertagWcities,
                                    icons: iconsW
                                },
                                {
                                    logoCustomer: '/images/partners/ubiflow.svg',
                                    titleCard: t('UBIFLOW_TITLE'),
                                    textCard: t('UBIFLOW_DESCRIPTION'),
                                    containertag: containertagUbiflow,
                                    icons: iconsUbiflow
                                },
                                {
                                    logoCustomer: '/images/partners/apidae.svg',
                                    titleCard: t('APIDAE_TITLE'),
                                    textCard: t('APIDAE_DESCRIPTION'),
                                    containertag: containertagApidae,
                                    icons: iconsApidae
                                },
                                {
                                    logoCustomer: '/images/partners/partoo.svg',
                                    titleCard: t('PARTOO_TITLE'),
                                    textCard: t('PARTOO_DESCRIPTION'),
                                    containertag: containertagPartoo,
                                    icons: iconsPartoo
                                }
                            ]}
                        />
                    </GalleryLayout>

                    <TwoColumnsLayout>
                        <TwoColumnsIcons
                            title={t('TECHNO_PROVIDERS')}
                            text={t('TECHNO_PARTNERS')}
                            titleButton={t('CTA_TECHNO')}
                            urlFooter='/contact/'
                        />
                    </TwoColumnsLayout>

                    <GalleryLayout>
                        <Gallery
                            cards={[

                                {
                                    logoCustomer: '/images/partners/argo.svg',
                                    titleCard: t('ARGO_TITLE'),
                                    textCard: t('ARGO_DESCRIPTION'),
                                    containertag: containertagArgo,
                                    icons: iconsArgo
                                },
                                {
                                    logoCustomer: '/images/partners/open-agenda.svg',
                                    titleCard: t('OA_TITLE'),
                                    textCard: t('OA_DESCRIPTION'),
                                    containertag: containertagOpenAgenda,
                                    icons: iconsOA
                                },
                                {
                                    logoCustomer: '/images/partners/polestar.png',
                                    titleCard: t('POLESTAR_TITLE'),
                                    textCard: t('POLESTAR_DESCRIPTION'),
                                    containertag: containertagPolestar,
                                    icons: iconsPolestar
                                }
                            ]}
                        />
                    </GalleryLayout>

                    <TwoColumnsLayout>
                        <TwoColumnsIcons
                            title={t('INTEGRATION_TITLE')}
                            text={t('COMMUNICATION_DESCRIPTION')}
                            titleButton={t('BUTTON_PARTNERS')}
                            urlFooter='/contact/'
                        />
                    </TwoColumnsLayout>

                    <BannerPicture
                        backgroundBanner={data.banner.childImageSharp}
                        alt='Footer banner with iPad and mobile view of Wemap map'
                        titleBannerPicture={t('TITLE_BANNER')}
                        titleButton={t('TITLE_BUTTON_TRY_OUT_NOW')}
                        buttonUrl='/contact/'
                    />
                </div>
            </Layout>
        </React.Fragment>
    );
};
IndexPage.propTypes = {data: PropTypes.object};

export default IndexPage;

export const query = graphql`{
  partners: file(relativePath: {eq: "partners/wemap-partners.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 616, layout: FIXED)
    }
  }
  banner: file(relativePath: {eq: "partners/banner-partners.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
}
`;
